import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import {
  AspectRatioWrapper,
  NamedLink,
  ResponsiveImage,
  PopupContainer,
  IconHeart,
  IconSpinner,
} from '../../components';
import { MdReceiptLong } from 'react-icons/md';
import priceImage from './images/price.png';
import css from './ListingCard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, removeFromWishlist } from '../../containers/WishlistPage/WishlistPage.duck';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isWishlistPage,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const { currentUser, userLoading } = state.user;
  const wishlist = currentUser?.attributes?.profile?.protectedData?.wishlist;
  const wishlistState = state.WishlistPage;
  const {
    addWishlistInProgress,
    addWishlistError,
    wishlistId,
    wishlistCount,
    removeWishlistLoading,
    removeListingId,
  } = wishlistState;
  const currentListing = ensureListing(listing);
  const wishListingCountInListing = currentListing?.attributes?.metadata?.wishlistCount;
  let finalWishlistCount = wishListingCountInListing;
  if (wishlistCount) {
    finalWishlistCount = wishlistCount[currentListing.id.uuid]
      ? wishlistCount[currentListing.id.uuid]
      : wishListingCountInListing;
  }
  // console.log('finalWishlistCount', wishlistCount);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const isListingInWishlist = wishlist?.some(item => item === id);
  const likeListingClasses = isListingInWishlist
    ? classNames(css.activeLikeListing, css.likeListing)
    : css.likeListing;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  // console.log('currentListing', currentListing.images);
  const priceTooltip = (
    <div className={css.toolTipContainer}>
      <div className={css.iconContainer}>
        <img src={priceImage} alt="price" className={css.priceImage} />
      </div>
      <h3 className={css.toolTipHeading}>
        <FormattedMessage id="ListingCard.priceTooltipHeading" />
      </h3>
      <p className={css.headingText}>
        <FormattedMessage id="ListingCard.priceTooltipText" />
      </p>
    </div>
  );
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    <div className={classes}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />
        </NamedLink>
      </AspectRatioWrapper>

      <div className={css.info}>
        <div className={css.container}>
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            <div>
              <PopupContainer body={priceTooltip} showButton={true} />
            </div>
          </div>
          {isWishlistPage ? null : (
            <div className={css.wishlist}>
              {currentUser ? (
                <div
                  className={likeListingClasses}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    dispatch(addToWishlist(listing.id.uuid));
                    // console.log('clicked');
                  }}
                >
                  <div>
                    {(addWishlistInProgress || userLoading) && wishlistId === listing.id.uuid ? (
                      <IconSpinner className={css.likeListingIcon} />
                    ) : (
                      <div>
                        <IconHeart className={css.likeListingIcon} />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {finalWishlistCount && currentUser ? (
                <div className={css.count}> ({finalWishlistCount})</div>
              ) : null}
            </div>
          )}
        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
        </div>
        {isWishlistPage ? (
          <div
            className={css.removeFromWishlist}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              dispatch(removeFromWishlist(listing.id.uuid));
              // console.log('clicked');
            }}
          >
            {removeWishlistLoading && removeListingId === listing.id.uuid ? (
              <IconSpinner className={css.likeListingIcon} />
            ) : (
              <FormattedMessage id="ListingCard.ListingCard.deleteFavLabel" />
            )}
            {/* Remove from favourite */}
          </div>
        ) : null}
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
