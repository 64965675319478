import React from 'react';
import css from './TermsOfService.module.css';
const TermsOfServiceFrench = () => {
  return (
    <div>
      <div>
        <h1>
          <b>
            <span lang="FR">CONDITIONS GÉNÉRALES D'UTILISATION DE SEWTOO.COM</span>
          </b>
        </h1>

        <p>
          <span lang="FR" className={css.firstParagraph}>
            La société SEWTOO SAS, ci-après Sewtoo, au capital social de 5.000 €, immatriculée au
            Registre du Commerce et des Sociétés de Chartres sous le numéro 921 691 937 dont le
            siège social est à Vernouillet (28500), 8 rue André-Marie Ampère, gère le site
            sewtoo.com accessible à l'adresse&nbsp;www.sewtoo.com (ci-après le « Sewtoo »).
          </span>
        </p>
        <p>
          <span lang="FR">
            Sewtoo est une marketplace permettant aux acheteurs et aux vendeurs de matériel de
            couture d’acheter/de vendre leurs articles à l’aide d’une interface en ligne
            (www.sewtoo.com) assurant et garantissant le paiement.
          </span>
        </p>
        <p>
          <span lang="FR">
            Sewtoo agit comme Tiers de confiance entre l'Acheteur et le Vendeur. Lorsqu'un Acheteur
            achète un produit, son règlement est conservé par Sewtoo. Cette somme est ensuite
            reversée au Vendeur lorsque l'Acheteur a confirmé qu'il a reçu l'article et qu'il en est
            satisfait.
          </span>
        </p>
        <p>
          <b>
            <span lang="FR">Article 1 . Définitions des termes utilisés</span>
          </b>
        </p>

        <p>
          <span lang="FR">
            Sewtoo&nbsp;: Marketplace permettant la mise en relation entre Acheteurs et Vendeurs de
            matériel de couture.
          </span>
        </p>
        <p>
          <span lang="FR">
            Acheteur&nbsp;: signifie un Utilisateur du site www.sewtoo.com qui désire acheter ou qui
            a déjà acheté un ou plusieurs Article(s)
          </span>
        </p>
        <p>
          <span lang="FR">
            Utilisateur&nbsp;: désigne un acheteur ou vendeur intéressé par les services proposés
            par Sewtoo
          </span>
        </p>
        <p>
          <span lang="FR">
            Vendeur&nbsp;: signifie un Utilisateur particulier proposant à la vente un ou plusieurs
            Articles(s).
          </span>
        </p>
        <p>
          <span lang="FR">
            Annonce&nbsp;: signifie l'offre de vente d'un ou de plusieurs Article(s) émise par le
            Vendeur sur Sewtoo.
          </span>
        </p>
        <p>
          <span lang="FR">
            Article&nbsp;: concerne le matériel de couture mis en vente par un Vendeur sur le Site,
            qui correspond aux critères définis à l'Article 5 des présentes Conditions générales et
            qui constitue l'objet du contrat entre le Vendeur et l'Acheteur.
          </span>
        </p>
        <p>
          <span lang="FR">
            Catalogue&nbsp;: représente l'ensemble des articles proposés à la vente sur
            www.sewtoo.com par un même Vendeur.
          </span>
        </p>
        <p>
          <span lang="FR">
            Commission&nbsp;: signifie la rémunération perçue par Sewtoo en contrepartie de
            l'utilisation du Site par les Utilisateurs. Cette commission est fixée à 5% du prix de
            vente de l’article plus 0,70 euro par commande. La commission de Sewtoo n’est prélevée
            que lorsque la vente a été effectuée et l’article reçu chez l’Acheteur.
          </span>
        </p>
        <p>
          <span lang="FR">
            Mode de livraison&nbsp;: l'expédition se fait obligatoirement par l’un des transporteurs
            proposés par Sewtoo ( Colissimo, Mondial relay, bpost…).{' '}
          </span>
        </p>
        <p>
          <span lang="FR">
            Prix de l'Article&nbsp;: signifie le prix proposé par le Vendeur pour la vente de son
            produit. Le Prix de l'Article ne comprend pas la Commission de Sewtoo ( 5% du prix de
            l’article + 0,70 euro ) ainsi que les Frais de Port.
          </span>
        </p>
        <p>
          <span lang="FR">
            Site&nbsp;: signifie le site Internet géré par la société Sewtoo accessible à
            l'adresse&nbsp;https://www.sewtoo.com
          </span>
        </p>

        <p>
          <span lang="FR">
            Tiers de confiance&nbsp;: Sewtoo agit comme Tiers de confiance entre l'acheteur et le
            vendeur. Lorsqu'un utilisateur achète un produit, son règlement est conservé par Sewtoo
            sur un compte séquestre. Cette somme est versée au vendeur lorsque l'acheteur a confirmé
            qu'il a reçu l'article et qu'il en est satisfait. Sewtoo garantit ainsi la sécurité du
            paiement et de la transaction au vendeur ainsi que l’assurance de la livraison du
            produit commandé à l’acheteur.
          </span>
        </p>
        <p>
          <strong>
            <span lang="FR">Compte </span>
          </strong>
          <strong>
            <span>Séquestre</span>
          </strong>
          <span>&nbsp;</span>
          <span lang="FR">: </span>
          <span>
            désigne le compte séquestre géré par le prestataire de services de paiement, sur lequel
            le montant d
          </span>
          <span lang="FR">e la transaction </span>
          <span>
            est conservé jusqu’à ce que la Transaction soit finalisée et que le paiement soit
            effectué
          </span>
          <span lang="FR">.</span>
        </p>
        <p>
          <strong>
            <span>Porte-monnaie électronique </span>
          </strong>
          <span lang="FR">:</span>
          <span>
            {' '}
            service financier fourni par le prestataire de services de paiement qui permet aux
            Utilisateurs de créer des porte-monnaies électroniques sur le Site. La solution de
            porte-monnaie électronique permet aux Utilisateurs de recevoir des fonds pour des
            Articles vendus sur le Site, de stocker les fonds, de les verser sur le compte bancaire
            de l’Utilisateur ou d’acheter des Articles à d’autres Utilisateurs sur le Site
          </span>
          <span lang="FR">.</span>
        </p>

        <p>
          <span lang="FR">
            Transaction&nbsp;: Montant incluant le prix de l’Article défini par le Vendeur, la
            commission Sewtoo ( ( 5% du prix de l’article + 0,70 euro ) ainsi que les Frais de Port.
          </span>
        </p>

        <p>
          <b>
            <span lang="FR">Article 2. Ouverture d’un compte</span>
          </b>
        </p>

        <p>
          <span lang="FR">
          L’inscription à Sewtoo est réservée à des Acheteurs et des Vendeurs particuliers et majeur. Les Utilisateurs professionnels ainsi que les utilisateurs mineurs ( moins de 18 ans ) ne sont pas acceptés sur la plateforme. 
          </span>
        </p>
        <p>
          <span lang="FR">2.1 Informations personnelles</span>
        </p>
        <p>
          <span lang="FR">
            Pour s’inscrire sur Sewtoo, l’Utilisateur ouvrira un compte à l’aide de son adresse
            email. L'Utilisateur fournira des informations complètes et exactes. Parmi les champs
            obligatoires, l’Utilisateur devra indiquer son prénom, son nom, son adresse email, son
            mot de passe et son pays de résidence.
          </span>
        </p>
        <p>
          <span lang="FR">
            Il s’engage à remettre à jour régulièrement ses données personnelles afin de les
            conserver vraies, exactes et complètes. Il sera tenu comme seul responsable des
            conséquences éventuelles de l’absence d’actualisation de ces informations.
          </span>
        </p>
        <p>
          <span lang="FR">
            Dans l'hypothèse où l’Utilisateur fournirait des informations fausses, inexactes,
            périmées ou incomplètes, la société Sewtoo se réserve le droit de lui refuser,
            immédiatement et pour le futur, l'accès à tout ou partie de la place de marché Sewtoo et
            de suspendre ou résilier son engagement contractuel.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le mot de passe de chaque Utilisateur est strictement personnel, l’Utilisateur s’engage
            à le conserver confidentiel. Dans l’hypothèse où l’Utilisateur aurait connaissance
            qu’une autre personne accède à son compte, il en informera Sewtoo dans les plus brefs
            délais et confirmera cette information par courrier.
          </span>
        </p>
        <p>
          <span lang="FR">
            L’Utilisateur s’engage à ne s’inscrire que sous un seul et unique profil. Ainsi, il
            s'engage à ne pas créer ou utiliser d'autres comptes que celui initialement créé, que ce
            soit sous sa propre identité ou celle de tiers. Toute dérogation à cette règle devra
            faire l'objet d'une demande explicite de la part de l’Utilisateur et d'une autorisation
            expresse, préalable et spécifique de Sewtoo. Le fait de créer ou utiliser de nouveaux
            comptes sous sa propre identité ou celle de tiers sans avoir demandé et obtenu
            l'autorisation de Sewtoo pourra entraîner la suspension immédiate des comptes de
            l’Utilisateur et de tous les services associés.
          </span>
        </p>
        <p>
          <span lang="FR">
            Lors d’une vente d’un article, l’Acheteur accepte que ses informations permettant la
            bonne livraison du produit telle que son nom, son prénom, son adresse de livraison
            soient envoyés au Vendeur. Les Utilisateurs s’engagent à garder confidentielles ces
            informations et à ne pas les divulguer. Ils s’engagent à ne pas porter atteinte au
            respect de la vie privée des autres Utilisateurs et à ne pas leur envoyer de message,
            d'objet, de documentation notamment publicitaire, par quelque moyen que ce soit
            (notamment par voie postale, téléphonique ou électronique).
          </span>
        </p>
        <p>
          <span lang="FR">2.2&nbsp;Règles de bonne conduite</span>
        </p>
        <p>
          <span lang="FR">
            L'Utilisateur s'engage à ne contacter un Vendeur qu’en cas de réel intérêt envers le
            produit mis en vente. L’Utilisateur s’engage à ne pas entrer directement en contact avec
            les autres Utilisateurs en dehors de l’espace de questions prévu à cet effet sur Sewtoo.
            Une vente effectuée en dehors du site Sewtoo ne saurait tenir le site pour responsable
            en cas de litige.
          </span>
        </p>
        <p>
          <span lang="FR">
            L’Utilisateur s’engage à ne pas employer sur Sewtoo, notamment par le biais de la
            description des articles, des propos contraires aux bonnes mœurs, injurieux,
            diffamatoires ou pouvant porter atteinte à la réputation des autres Utilisateurs. En cas
            de non-respect de cette obligation, Sewtoo se réserve le droit de suspendre le compte de
            l’Utilisateur sans notification préalable. En cas de récidive, Sewtoo se réserve le
            droit de clôturer définitivement le compte de l’Utilisateur.
          </span>
        </p>
        <p>
          <span lang="FR">
            L’Utilisateur s’interdit de publier des photographies dont le contenu serait inapproprié
            (nudité, violence…). Sewtoo se réserve le droit de supprimer tout contenu jugé
            inapproprié et également de supprimer les comptes des Utilisateurs dans certains cas.
          </span>
        </p>
        <p>
          <b>
            <span lang="FR">Article 3 : protection des données personnelles</span>
          </b>
        </p>
        <p>
          <span lang="FR">
            3.1.&nbsp;Sewtoo s'engage à ne pas porter atteinte à la vie privée des Utilisateurs de
            Sewtoo et à la confidentialité des informations fournies.
          </span>
        </p>
        <p>
          <span lang="FR">
            L’Utilisateur peut communiquer ses numéros de téléphones fixes et portables ainsi que
            les informations contenues sur son RIB et d’autres informations facultatives. Le
            caractère obligatoire ou facultatif des données à fournir est signalé lors de leur
            collecte par une barre de soulignement jaune.
          </span>
        </p>
        <p>
          <span lang="FR">
            Ces informations personnelles seront requises en cas d’inscription sur le site, de mise
            à jour de la page Informations personnelles, lors de l’achat ou de la vente d’un
            Article, de la navigation sur Sewtoo et de la consultation d’Articles du catalogue ou
            encore de contact avec le Service Client.
          </span>
        </p>
        <p>
          <span lang="FR">
            Lors de l’inscription sur Sewtoo, l’Utilisateur pourra choisir de recevoir des offres
            notamment commerciales envoyées par Sewtoo et/ou ses partenaires.
          </span>
        </p>
        <p>
          <span lang="FR">
            En aucun cas l'adresse postale de l'Utilisateur ne sera communiquée à un autre
            Utilisateur sauf en cas d'Achat d'un Article.
          </span>
        </p>
        <p>
          <span lang="FR">
            3.2.&nbsp;Conformément à la loi « Informatique et Libertés » n°78-17 du 6 janvier 1978,
            l'Utilisateur peut à tout moment accéder aux informations personnelles le concernant
            qu'il a communiquées à Sewtoo, et/ou en demander la rectification, le complément, la
            clarification, la mise à jour ou la suppression en s'adressant au Service Client via la
            rubrique Contactez-nous ou par simple lettre (Sewtoo, 8 rue André-Marie Ampère, 28500
            Vernouillet) en indiquant ses nom, prénom, date et lieu de naissance, adresse postale et
            adresse email.
          </span>
        </p>
        <p>
          <span lang="FR">
            3.3.&nbsp;L'Utilisateur accepte que Sewtoo conserve tous documents, informations et
            enregistrements concernant son activité sur le Site.
          </span>
        </p>
        <p>
          <span lang="FR">
            3.4.&nbsp;L'Utilisateur accepte que Sewtoo puisse retoucher les photos des articles
            qu'il aura mis en ligne afin d'optimiser leur visibilité. L'Utilisateur accepte
            également que Sewtoo communique sur certains articles qu'il aura mis en vente. Cela
            toujours dans le but de faciliter et accélérer la vente de son/ses produits.
          </span>
        </p>

        <p>
          <b>
            <span lang="FR">Article 4 - Rôle, droits et obligations de Sewtoo</span>
          </b>
        </p>

        <p>
          <span lang="FR">4.1. Rôle de mise en relation des Vendeurs et des Acheteurs</span>
        </p>
        <p>
          <span lang="FR">
            Sewtoo s’engage à mettre à disposition des Utilisateurs des outils organisationnels et
            fonctionnels de nature à leur permettre la mise en relation de Vendeurs et d’Acheteurs
            et leur permettre, le cas échéant, de concrétiser une vente de Produit.
          </span>
        </p>
        <p>
          <span lang="FR">
            Sewtoo met à la disposition du Vendeur un outil de publication et de diffusion de ses
            offres de vente sur la place de marché Sewtoo. Cet outil permet au Vendeur de mettre en
            ligne des annonces d’offres de vente de Produits en décrivant leurs caractéristiques.
          </span>
        </p>
        <p>
          <span lang="FR">
            En conséquence, Sewtoo n’est aucunement revendeur des Produits, ne dispose jamais des
            Produits proposés à la vente, n’apporte aucune garantie quant à leur caractéristique,
            leur état d’usure, leur état de fonctionnement, et plus généralement ne peut garantir
            que le Produit du Vendeur satisfera l'Acheteur, que celui-ci ne sera pas retourné ou que
            la vente ne sera pas annulée par l’Acheteur.
          </span>
        </p>
        <p>
          <span lang="FR">4.2. Tiers de confiance</span>
        </p>
        <p>
          <span lang="FR">
            Sewtoo est expressément autorisé par le Vendeur à percevoir le Prix de la Transaction au
            nom et pour le compte du Vendeur au moyen du système sécurisé de paiement électronique
            mis en place sur la place de marché Sewtoo. Le système de paiement sécurisé est
            MangoPay,{' '}
          </span>
          <span style={{ fontFamily: '"Helvetica Neue"' }}>
            société anonyme de droit luxembourgeois, au capital de 2 000 000 euros, dont le siège
            social est situé 59 Boulevard Royal, L-2449 Luxembourg et immatriculée au Registre du
            commerce et des sociétés luxembourgeois sous le numéro B173459, habilitée à exercer son
            activité en France en libre établissement, en qualité d’établissement de monnaie
            électronique agréé par la Commission de Surveillance du Secteur Financier, 110 route
            d’Arlon L-1150 Luxembourg,{' '}
          </span>
        </p>
        <p>
          <span lang="FR">4.3. Disponibilité de www.sewtoo.fr</span>
        </p>
        <p>
          <span lang="FR">
            La société Sewtoo ne garantit aucunement la disponibilité du service en cas d’incidents
            techniques du réseau Internet ou de dysfonctionnements des liaisons téléphoniques.
          </span>
        </p>
        <p>
          <span lang="FR">
            La société Sewtoo ne garantit aucunement la vitesse d’accès à la place de marché Sewtoo
            depuis d’autres sites dans le monde.
          </span>
        </p>
        <p>
          <span lang="FR">
            Dans le cadre de la maintenance de son site, la société Sewtoo se réserve le droit
            d’interrompre temporairement ses services.
          </span>
        </p>
        <p>
          <span lang="FR">
            En cas d’inaccessibilité du service due à des dysfonctionnements techniques du ressort
            de la société Sewtoo ou de ses sous-traitants habituels, la société Sewtoo fera ses
            meilleurs efforts pour trouver une réponse aux problèmes révélés par l’Utilisateur.
          </span>
        </p>
        <p>
          <span lang="FR">
            La responsabilité de la société Sewtoo ne pourra être engagée&nbsp;:
          </span>
        </p>
        <p>
          <span lang="FR">
            -si son serveur est indisponible pour des raisons de force majeure telles que notamment
            défaillances de longue durée du réseau public de distribution d’électricité, grèves,
            émeutes, guerres, tempêtes, tremblements de terre, défaillances du réseau public des
            télécommunications, pertes de connectivité Internet dues aux opérateurs publics et
            privés dont dépend la société Sewtoo;
          </span>
        </p>
        <p>
          <span lang="FR">
            -;pour toute raison technique qui aurait pour conséquence la perte de Contenu stocké ou
            en cours de téléchargement sur le serveur de la société Sewtoo.
          </span>
        </p>
        <p>
          <span lang="FR">
            Il appartient en effet aux Utilisateurs de conserver une copie personnelle du Contenu
            publié.
          </span>
        </p>
        <p>
          <b>
            <span lang="FR">Article 5. Rôle, droits et obligations du Vendeur</span>
          </b>
        </p>

        <p>
          <span lang="FR">5.1. Dispositions générales pour la mise en vente d’un produit</span>
        </p>
        <p>
          <span lang="FR">
          Aucun vendeur professionnel ou mineur ( moins de 18 ans ) n’est autorisé à créer un compte sur sewtoo.com. Sewtoo se réserve le droit de suspendre immédiatement le compte de tout vendeur considéré comme professionnel ou mineur ( moins de 18 ans ).
          </span>
        </p>
        <p>
          <span lang="FR">
            Le Vendeur s'engage à ce que tout Article mis en vente corresponde à un bien matériel
            disponible immédiatement dont il dispose de la pleine et entière propriété et capacité
            de vente. Le Vendeur s'engage à ce que les Articles ne comportent pas de tache visible,
            de trou, de déchirure, de trace indélébile et que les dimensions indiquées soit
            correctes. Dans le cas de machines, le Vendeur s'engage à ce que celles-ci soient en
            état de fonctionnement (tout problème technique rencontré sur la machine devra être
            clairement mentionné dans sa description). Dans le cas où de tels articles seraient
            expédiés à un acheteur, Sewtoo se réserve le droit d’imposer au vendeur le remboursement
            total des articles concernés à l’acheteur. En cas de demande de retour de la marchandise
            par le vendeur, les Frais de Port liés à leur réexpédition seraient à la charge du
            Vendeur.
          </span>
        </p>
        <p>
          <span lang="FR">Le Vendeur s'engage également à maintenir son inventaire à jour.</span>
        </p>
        <p>
          <span lang="FR">
            Le Vendeur s’engage à informer les Acheteurs des caractéristiques essentielles de
            l'Article. Il est seul responsable de la description des biens qu'il propose à la vente.
            Il garantit Sewtoo de toute réclamation relative au contenu et à la forme de ladite
            description si celle-ci venait à être erronée. Le Vendeur s'engage à effectuer toutes
            validations, vérifications et à apporter toutes précisions nécessaires de sorte que le
            descriptif et les éléments de l'offre soient fidèles et n'induisent pas l'Acheteur en
            erreur.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le Vendeur s’engage à ne pas insérer dans l’annonce de liens ayant pour objet ou pour
            effet de renvoyer les utilisateurs de la place de marché Sewtoo vers des contenus situés
            en dehors du site Sewtoo. Le Vendeur s’interdit d’insérer tout lien pointant vers son
            adresse de courrier électronique ou, plus généralement, s’interdit d’insérer toute
            publicité (bannière, lien commercial, etc.) au bénéfice ou au profit de tout site ou
            tout contenu qui ne serait pas hébergé sur le site Sewtoo.
          </span>
        </p>
        <p>
          <span lang="FR">5.2. Interdictions de vente</span>
        </p>
        <p>
          <span lang="FR">
            Le Vendeur s'engage à ce que les Articles soient parfaitement conformes à l'ordre
            public, aux bonnes mœurs, au droit en vigueur en France, ainsi qu'aux droits des tiers
            et qu'ils ne soient pas susceptibles de heurter la sensibilité des mineurs.
          </span>
        </p>
        <p>
          <span lang="FR">
            Sewtoo se réserve le droit de demander pour preuve de bonne foi une facture prouvant la
            propriété de l’Article mis en ligne par le Vendeur ou des photographies supplémentaires
            sur son Article.
          </span>
        </p>
        <p>
          <span lang="FR">
            Sewtoo se réserve le droit de supprimer une annonce si elle ne respecte pas ces
            présentes Conditions générales de vente.
          </span>
        </p>
        <p>
          <span lang="FR">5.3. Modifications des annonces</span>
        </p>
        <p>
          <span lang="FR">
            Sewtoo se réserve le droit de solliciter un Vendeur afin qu'il modifie et/ou supprime
            son Annonce et/ou de le faire elle-même directement, notamment si le Vendeur n'a pas
            publié son Annonce dans la catégorie adéquate ou que l’Annonce ne correspond pas au
            produit mis en vente.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le Vendeur s'engage à ne publier que des photos des Articles qu'il a lui-même prises.
            Sewtoo se réserve le droit de retirer de son site toute photo qui n'aurait pas été prise
            par le Vendeur.
          </span>
        </p>
        <p>
          <span lang="FR">5.4. Détermination du prix de vente&nbsp;</span>
        </p>
        <p>
          <span lang="FR">
            Le Vendeur détermine le Prix de vente de l'Article sous sa seule responsabilité. Sewtoo
            attire notamment l'attention du Vendeur sur le fait qu'il est de sa responsabilité de ne
            pas fixer un Prix trop élevé, ceci dans l’intérêt de la conclusion d’une vente.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le Vendeur peut indiquer le Prix de vente neuf. Si le Vendeur ne connait pas le prix
            exact de vente en boutique, il peut indiquer sa meilleure estimation. Ce Prix correspond
            à une indication du prix auquel le vendeur déclare avoir acheté l'Article qu'il propose
            à la vente d'occasion. L'Utilisateur est averti que ce Prix de vente neuf n'a pas été
            vérifié par Sewtoo.
          </span>
        </p>
        <p>
          <span lang="FR">5.5 Demande d’informations complémentaires de la part d’un Acheteur</span>
        </p>
        <p>
          <span lang="FR">
            Lorsqu’un Acheteur est intéressé par une offre de vente d’un produit, le Vendeur recevra
            par mail une demande d’informations complémentaires.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le Vendeur s’engage à ne jamais proposer une vente en direct à l’Acheteur. Si toutefois
            une telle vente s’avérait à être effectuée, Sewtoo se réserve le droit de suspendre le
            compte du Vendeur et de l’Acheteur. De plus, Sewtoo ne pourra pas alors garantir ni le
            paiement ni le bon envoi des articles commandés.
          </span>
        </p>
        <p>
          <b>
            <span lang="FR">Article 6. Rôle, droits et obligations de l’Acheteur</span>
          </b>
        </p>

        <p>
          <span lang="FR">
          L’Acheteur s’engage à acheter à titre personnel les Articles proposés sur Sewtoo. Aucun Acheteur professionnel ou mineur ( moins de 18 ans ) n’est autorisé à créer un compte sur sewtoo.com. Sewtoo se réserve le droit de suspendre immédiatement le compte de tout acheteur considéré comme professionnel ou mineur ( moins de 18 ans ).
          </span>
        </p>
        <p>
          <span lang="FR">6.1&nbsp;&nbsp;&nbsp;Information de l’Acheteur</span>
        </p>
        <p>
          <span lang="FR">
            L'Acheteur reconnaît expressément que son acceptation à une offre de vente dépendra de
            la description par le Vendeur sur la place de marché Sewtoo des Produit(s) offerts à la
            vente
          </span>
        </p>
        <p>
          <span lang="FR">
            L'Acheteur reconnaît expressément que le contrat de Vente est conclu sous la condition
            résolutoire que le Produit commandé soit disponible. La confirmation de la disponibilité
            du Produit par le Vendeur emporte la levée de la condition résolutoire. En cas
            d’infirmation de la disponibilité du Produit, le contrat de Vente conclu entre
            l'Acheteur et le Vendeur est automatiquement et immédiatement résolu (annulé) et les
            obligations de chacune des parties sont révoquées de plein droit. Il est précisé qu’en
            cas de contrat de Vente portant sur plusieurs Produits, seul sera résolu le contrat de
            Vente portant sur le Produit indisponible.
          </span>
        </p>
        <p>
          <span lang="FR">6.2&nbsp;&nbsp;&nbsp;Demande de renseignements complémentaires</span>
        </p>
        <p>
          <span lang="FR">
            Sewtoo met à la disposition de l’Acheteur des outils informatiques lui permettant de
            faire une demande auprès du Vendeur pour obtenir des renseignements complémentaires en
            sus de la description de l’Article.
          </span>
        </p>
        <p>
          <span lang="FR">
            L’Acheteur s’engage à ne jamais proposer une vente en direct à un Vendeur. Si toutefois
            une telle vente s’avérait à être effectuée, Sewtoo se réserve le droit de suspendre le
            compte du Vendeur et de l’Acheteur. De plus, Sewtoo ne pourra pas alors garantir ni le
            paiement ni le bon envoi des articles commandés.
          </span>
        </p>
        <p>
          <b>
            <span lang="FR">Article 7. Conclusion d’une vente</span>
          </b>
        </p>

        <p>
          <span lang="FR">7.1. Conclusion de la vente / Passation de la commande</span>
        </p>
        <p>
          <span lang="FR">
            7.1.1.&nbsp;Pour confirmer sa commande, l’Acheteur doit procéder au paiement du prix de
            la Transaction.
          </span>
        </p>
        <p>
          <span lang="FR">
            7.1.2&nbsp;Sewtoo notifie le Vendeur de l'Achat de l'Acheteur par courrier électronique.
          </span>
        </p>
        <p>
          <span lang="FR">
            7.1.3.&nbsp;Les Offres d'Achat formulées par l'Acheteur sont indépendantes les unes des
            autres. Si une ou plusieurs des Offres d'Achat n'a ou n'ont pas fait l'objet
            d'acceptation par le(s) Vendeur(s) concerné(s), les autres Offres d'Achat de l'Acheteur
            ne sont pas caduques et lient l'Acheteur.
          </span>
        </p>
        <p>
          <span lang="FR">7.2. Paiement de la commande</span>
        </p>
        <p>
          <span lang="FR">7.2.1. Paiement au comptant</span>
        </p>
        <p>
          <span lang="FR">Paiements sécurisés via le système de paiement intégré.</span>
        </p>
        <p>
          <span lang="FR">
            Sewtoo a conclu un accord avec MANGOPAY SA pour traiter les paiements effectués à l'aide
            du Système de Paiement Intégré et stocker les informations relatives aux cartes de
            crédit, cartes de débit et tout autre moyen de paiement proposé sur le Site. Afin
            d'utiliser le Système de Paiement Intégré, l'Utilisateur conclura un contrat directement
            avec MANGOPAY S.A. et acceptera les conditions générales de MANGOPAY SA. Les
            utilisateurs seront invités à le confirmer lors de la création de leur porte-monnaie
            électronique.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le paiement du Prix Total peut être effectué par carte de crédit, carte de débit ou tout
            autre moyen de paiement proposé sur le Site. Si une carte de crédit, une carte de débit
            ou un autre moyen de paiement suspecté d'appartenir à un tiers est fourni par un
            Utilisateur sur le Site, l'Utilisateur peut être tenu de fournir une preuve
            supplémentaire que la carte de crédit, la carte de débit ou l'autre moyen de paiement
            est bien les utilisateurs. Si l'Acheteur dispose de fonds disponibles dans le
            porte-monnaie électronique (Solde), ces fonds seront automatiquement utilisés pour
            effectuer des achats sur le Site auprès d'autres Utilisateurs. Si l'Acheteur ne dispose
            pas de fonds suffisants dans son portefeuille électronique pour couvrir le montant total
            dû, il peut couvrir le solde en utilisant d'autres moyens de paiement disponibles.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le Prix Total est détenu sur un compte Séquestre géré par MANGOPAY SA. Lorsqu'une
            Transaction est effectuée, MANGOPAY SA reçoit les fonds de l'Acheteur pour le compte du
            Vendeur et conserve ces fonds sous Séquestre à des fins de décaissement.
          </span>
        </p>
        <p>
          <span lang="FR">
            Sewtoo accompagne ses Utilisateurs en leur proposant les services fournis par MANGOPAY
            SA et leur apporte une assistance directe dans le cadre de ces services. Il est
            toutefois précisé, afin d'éviter toute ambiguïté, que Sewtoo ne fournit aucun service de
            traitement des paiements aux Utilisateurs. Les utilisateurs sont responsables de fournir
            des informations de contact exactes pour les cartes de crédit, les cartes de débit et
            tout autre mode de paiement proposé sur le site. Sewtoo décline toute responsabilité
            quant aux informations fournies par l'Utilisateur dans toute la mesure permise par la
            loi applicable.
          </span>
        </p>
        <p>
          <span lang="FR">
            En aucun cas, Sewtoo ne stocke ou n'a accès aux coordonnées bancaires des Acheteurs.
          </span>
        </p>
        <p>
          <span lang="FR">7.2.2. Terminer une transaction.</span>
        </p>
        <p>
          <span lang="FR">
            Le Prix Total payé par l'Acheteur est conservé sur un compte Séquestre par MANGOPAY SA
            jusqu'à la finalisation de la Transaction.
          </span>
        </p>
        <p>
          <span lang="FR">La Transaction est finalisée&nbsp;:</span>
        </p>
        <p>
          <span lang="FR">
            (i) lorsque l'Acheteur clique sur le bouton Tout est OK pendant la Période de
            contestation&nbsp;; Où
          </span>
        </p>
        <p>
          <span lang="FR">
            (ii) automatiquement à l'expiration de la Période de litige, si l'Acheteur n'a signalé
            aucun problème via le processus J'ai un problème pendant la Période de litige&nbsp;; Où
          </span>
        </p>
        <p>
          <span lang="FR">
            (iii) après la résolution d'un problème signalé pendant la Période du litige.
          </span>
        </p>
        <p>
          <span lang="FR">
            Dès que la Transaction est finalisée, le prix d'achat de l'Article est transférés par
            MANGOPAY SA sur le porte-monnaie électronique du Vendeur. L'Utilisateur a également la
            possibilité de demander à MANGOPAY SA de transférer les fonds stockés dans son
            porte-monnaie électronique vers son compte bancaire personnel, sans frais de transfert.
            Toutefois, pour le premier paiement, l'Utilisateur doit fournir les coordonnées de son
            compte bancaire.
          </span>
        </p>
        <p>
          <span lang="FR">7.2.2. Contenu du paiement</span>
        </p>
        <p>
          <span lang="FR">Le montant payé par l'Acheteur comprend :</span>
        </p>
        <p>
          <span lang="FR">-le Prix de l'Article déterminé par le Vendeur, </span>
        </p>
        <p>
          <span lang="FR">
            -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; La commission Sewtoo de 5% du prix de vente
            de l’article plus 0,70 euro par commande.
          </span>
        </p>
        <p>
          <span lang="FR">
            -Les frais de Port fixés par Sewtoo en fonction des tarifs Colissimo et Mondial Relay ou
            tout autre transporteur proposé sur la plateforme.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le montant des Frais de Port payé par l'Acheteur variera en fonction de l’article
            acheté.
          </span>
        </p>
        <p>
          <span lang="FR">
            L'Acheteur est débité du montant total de la transaction ( Prix de l’article +
            commission Sewtoo + Frais de port ) lorsqu’il confirme sa commande en cliquant sur le
            bouton «&nbsp;confirmer ma commande et payer&nbsp;». Si l'Acheteur ne reçoit pas
            l'Article ou n'en est pas satisfait, son compte en banque ou son portefeuille Sewtoo (
            en fonction du mode de règlement sélectionné par l’acheteur lors de la confirmation de
            sa commande ) sera recrédité.
          </span>
        </p>
        <p>
          <span lang="FR">
            Les tarifs de La Poste pour l’expédition de marchandises sont calculés en fonction du
            poids du colis.{' '}
          </span>
        </p>
        <p>
          <span lang="FR">
            Les tarifs de Mondial Relay pour l’expédition de marchandises sont calculés en fonction
            du poids du colis.
          </span>
        </p>
        <p>
          <span lang="FR">
            7.3. Restrictions d'utilisation du Système de Paiement Intégré&nbsp;:
          </span>
        </p>
        <p>
          <span lang="FR">7.3.1 Interdiction d'utilisation par des tiers. </span>
        </p>
        <p>
          <span lang="FR">
            Le droit d'utiliser le Système de Paiement Intégré est exclusivement réservé aux ventes
            réalisées pour le compte de l'Utilisateur. L'Utilisateur ne peut revendre, louer ou, de
            toute autre manière, permettre à des tiers d'utiliser le Système de paiement intégré
            dans le but de rémunérer des services fournis par des tiers.
          </span>
        </p>
        <p>
          <span lang="FR">7.3.2 Articles autorisés uniquement. </span>
        </p>
        <p>
          <span lang="FR">
            Le Système de Paiement Intégré est uniquement réservé aux Transactions portant sur des
            Articles figurant sur le Site, à l'exclusion de tout autre produit ou service.
          </span>
        </p>
        <p>
          <span lang="FR">
            7.4. Devise. Tous les prix affichés sur le Site sont exprimés en euros (EUR) et incluent
            la TVA applicable.
          </span>
        </p>
        <p>
          <span lang="FR">7.5 PORTEFEUILLE ÉLECTRONIQUE (BALANCE)</span>
        </p>
        <p>
          <span lang="FR">7.5.1 Paramètres du portefeuille électronique.</span>
        </p>
        <p>
          <span lang="FR">
            Le Porte-Monnaie Electronique (ou Solde) est un service de paiement fourni par MANGOPAY
            SA. Lorsqu'un Utilisateur crée son porte-monnaie électronique, il conclut un contrat
            directement avec MANGOPAY SA et accepte les conditions générales de MANGOPAY SA. Les
            utilisateurs sont invités à le confirmer lors de la création du portefeuille
            électronique. Pour la première Transaction de Vente, il sera demandé au Vendeur d'ouvrir
            un porte-monnaie électronique en fournissant ses nom, prénom, date de naissance,
            adresse, nationalité (le cas échéant) et numéro de téléphone (le cas échéant). Si le
            Vendeur ne le fait pas dans un délai de cinq (5) jours, la Transaction de vente sera
            annulée. Avant la première transaction de vente, les vendeurs peuvent également ouvrir
            le porte-monnaie électronique de leur propre initiative via la section des paramètres du
            compte (en sélectionnant Solde puis en cliquant sur Activer le solde) et en fournissant
            les données requises.
          </span>
        </p>
        <p>
          <span lang="FR">7.5.2 Vérifications KYC.</span>
        </p>
        <p>
          <span lang="FR">
            Une fois la confirmation du porte-monnaie électronique effectuée et le porte-monnaie
            électronique ouvert, les Utilisateurs sont soumis aux procédures réglementaires mises en
            place par MANGOPAY SA, telles que les vérifications d'identité et autres exigences en
            matière de Know Your Customer (ou KYC), lorsque ils atteignent les seuils fixés par
            MANGOPAY SA. MANGOPAY SA pourra également effectuer des vérifications KYC sur tout
            Utilisateur qu'il soupçonnerait de se livrer à des pratiques potentiellement
            frauduleuses ou suspectes. Si un utilisateur ne respecte pas les exigences KYC, son
            porte-monnaie électronique peut être suspendu et l'utilisateur ne pourra pas transférer
            de fonds tant que son identité n'aura pas été confirmée. Si un Utilisateur a téléchargé
            un document frauduleux ou entrepris toute autre action conduisant MANGOPAY SA à
            considérer le comportement de cet Utilisateur comme frauduleux, l'Utilisateur sera
            réputé ne pas se conformer aux exigences KYC et ne pourra pas décaisser les fonds. ou en
            disposer.
          </span>
        </p>
        <p>
          <span lang="FR">
            7.5.3 Transfert de fonds détenus dans un porte-monnaie électronique.
          </span>
        </p>
        <p>
          <span lang="FR">
            Les utilisateurs peuvent transférer des fonds de leur portefeuille électronique vers
            leur compte bancaire. Ce transfert sera effectué par MANGOPAY SA. Pour activer cette
            option, l'Utilisateur doit au préalable accéder aux paramètres de son compte Utilisateur
            et renseigner son numéro de compte bancaire, ses nom, prénom et adresse de facturation.
            Si l'Utilisateur atteint les seuils KYC ou si MANGOPAY SA soupçonne que l'Utilisateur se
            livre à des activités potentiellement suspectes ou frauduleuses, l'Utilisateur ne pourra
            pas transférer de fonds tant qu'il n'aura pas satisfait aux exigences KYC applicables.
          </span>
        </p>
        <p>
          <span lang="FR">7.6. Livraison de l'Article</span>
        </p>
        <p>
          <span lang="FR">
            Lors de l’expédition de l’Article, le Vendeur s’engage à adresser l’Article
            convenablement emballé et de manière à ce que l’Article ne puisse pas être impacté
            durant le transport. Le Vendeur doit conserver les preuves d'expédition du colis pendant
            le mois suivant la date d'expédition.&nbsp;
          </span>
        </p>
        <p>
          <span lang="FR">7.6.1. Livraison chez l’Acheteur&nbsp;</span>
        </p>
        <p>
          <span lang="FR">
            Dès lors que Sewtoo a encaissé le règlement du Prix de la Transaction de l'Acheteur,
            Sewtoo communiquera au Vendeur les coordonnées de l'Acheteur afin qu'il puisse procéder
            à la livraison de l'Article.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le Vendeur est seul responsable de la bonne exécution de l'obligation de livrer
            l'Article commandé. Le Vendeur s'engage à adresser l'Article convenablement emballé dans
            les 96 heures suivant la date de mise à disposition de l’étiquette d’expédition
            préremplie avec l'adresse de l'acheteur ( le vendeur recevra cette étiquette par email
            et elle sera mise à disposition pour téléchargement au niveau de son compte client
            aussitôt la commande validée par l’acheteur ). En cas de non-respect par le Vendeur des
            conditions d'expédition ici exprimées, Sewtoo annulera la transaction et remboursera
            l’acheteur en conséquence. Le vendeur s’engage à ne pas utiliser l’étiquette
            d’expédition qui aura été mise à sa disposition par Sewtoo passé le délai d’expédition
            de 96 heures. Toute utilisation d’une étiquette d’expédition après le délai d’expédition
            de 96 heures se traduira par la facturation au vendeur des frais suivant par
            Sewtoo&nbsp;: montant des frais de port initialement calculé sur la base de la commande
            concernée multiplié par deux + frais de dossier de 16,50 euros. L’acheteur devra
            s’acquitter de la totalité des frais facturés par Sewtoo afin de pouvoir continuer à
            utiliser son compte utilisateur. Sewtoo se réserve le droit de désactiver le compte de
            l’acheteur jusqu’au règlement des sommes facturées.
          </span>
        </p>
        <p>
          <span lang="FR">
            Afin de réduire les risques lors de la livraison, et de permettre à l'Acheteur et au
            Vendeur de suivre l'expédition de l'Article, l'expédition doit se faire obligatoirement
            par le biais du système d’expédition mis à disposition sur Sewtoo.{' '}
          </span>
        </p>
        <p>
          <span lang="FR">
            Le Vendeur doit conserver les preuves d'expédition du colis pendant le mois suivant la
            date d'expédition.
          </span>
        </p>
        <p>
          <span lang="FR">7.6.2 Réception de l'Article</span>
        </p>
        <p>
          <span lang="FR">
            Dès la réception des Produit(s) commandés, l'Acheteur s’engage à confirmer au travers du
            service client à Sewtoo dans un délai de 5 (cinq) jours ouvrables la conformité du ou
            des produits. À défaut, l'Acheteur déposera une réclamation dans le même délai à l’aide
            du service client.
          </span>
        </p>
        <p>
          <span lang="FR">
            À défaut d’avoir déclaré la bonne conformité des produits commandés ou d’avoir déposé
            une réclamation sur la place de marché Sewtoo dans les 5 (cinq) jours ouvrables à
            compter de la réception de la commande, la transaction est réputée parfaite. La
            transaction est également réputée parfaite dès confirmation de la part de l'Acheteur de
            la bonne conformité des Produits commandés.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le montant de la transaction diminué de la commission Sewtoo et des frais de port sera
            ainsi reversé au Vendeur.&nbsp;
          </span>
        </p>
        <p>
          <b>
            <span lang="FR">Article 8. Conditions financières</span>
          </b>
        </p>

        <p>
          <span lang="FR">8.1. Paiement du Vendeur</span>
        </p>
        <p>
          <span lang="FR">
            Le Vendeur autorise Sewtoo à percevoir, en son nom et pour son compte, le Prix de la
            Transaction par le biais du système sécurisé de paiement électronique mis en place sur
            le Site (ici l’interface MangoPay) ou par tout autre moyen, qui serait ultérieurement
            mis en place par Sewtoo.
          </span>
        </p>
        <p>
          <span lang="FR">8.2 Rémunération de Sewtoo</span>
        </p>
        <p>
          <span lang="FR">
            La Commission facturée à l’acheteur par Sewtoo pour chaque achat est égale à 0,70 euro
            par transaction + 5% du prix de l’article TTC . Les frais de port ne sont pas inclus
            dans la base de calcul de la Commission.{' '}
          </span>
        </p>
        <p>
          <span lang="FR">
            La commission de Sewtoo est due seulement en cas de livraison du/des Produits commandés
            et en l’absence d’annulation de la vente, pour quelque raison que ce soit.
          </span>
        </p>
        <p>
          <span lang="FR">8.3 Règlement des frais de port </span>
        </p>
        <p>
          <span lang="FR">
            Les frais de port facturés à l’acheteur par Sewtoo pour chaque achat varient en fonction
            des adresses du vendeur et de l’acheteur, du poids et de la taille du colis, du
            transporteur sélectionné par l’acheteur.
          </span>
        </p>
        <p>
          <span lang="FR">Les frais de port pourront être remboursés à l’acheteur si&nbsp;:</span>
        </p>
        <p>
          <span lang="FR">
            -<span>&nbsp;&nbsp; </span>
          </span>
          <span lang="FR">L’article n’a pas été expédié par le vendeur dans le délai imparti.</span>
        </p>
        <p>
          <span lang="FR">&nbsp;</span>
        </p>
        <p>
          <span lang="FR">Ou </span>
        </p>
        <p className="MsoListParagraph">
          <span lang="FR">
            -<span>&nbsp;&nbsp; </span>
          </span>
          <span lang="FR">
            L’article a été perdu par le transporteur lors de son acheminement.{' '}
          </span>
        </p>
        <p>
          <span lang="FR">
            La validation du remboursement ainsi que le délai de remboursement des frais de port
            sont, dans ce cas, fonction du résultat de l’enquête et du délai de cette dernière
            défini par les conditions générales d’utilisation du transporteur sélectionné par
            l’acheteur.
          </span>
        </p>
        <p>
          <span lang="FR">Ou</span>
        </p>
        <p className="MsoListParagraph">
          <span lang="FR">
            -<span>&nbsp;&nbsp; </span>
          </span>
          <span lang="FR">
            le colis a été signalé par l’acheteur comme endommagé au transporteur lors de sa
            livraison ou de sa collecte en point relais.{' '}
          </span>
        </p>
        <p>
          <span lang="FR">
            La validation du remboursement ainsi que le délai de remboursement des frais de port
            sont, dans ce cas, fonction du résultat de l’enquête et du délai de cette dernière
            défini par les conditions générales d’utilisation du transporteur sélectionné par
            l’acheteur.
          </span>
        </p>
        <p>
          <span lang="FR">Ou, si les conditions suivantes sont réunies &nbsp;:</span>
        </p>
        <p>
          <span lang="FR">
            -<span>&nbsp;&nbsp; </span>
          </span>
          <span lang="FR">
            L’acheteur a effectué une réclamation adressé au vendeur respectant les
          </span>
        </p>
        <p>
          <span lang="FR">
            termes de la garantie Satisfait ou Remboursé énoncé à l’article 9 des présentes
            conditions générales d’utilisation.
          </span>
        </p>
        <p>
          <span lang="FR">
            -<span>&nbsp;&nbsp; </span>
          </span>
          <span lang="FR">
            Suite à cette réclamation, le vendeur a demandé le retour de l’article à ses frais.{' '}
          </span>
        </p>
        <p>
          <span lang="FR">
            -<span>&nbsp;&nbsp; </span>
          </span>
          <span lang="FR">
            L’acheteur a retourné l’article en respectant les termes de la garantie Satisfait ou
            Remboursé énoncés à l’article 9 des présentes conditions générales d’utilisation.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le remboursement de l'Acheteur se fera sous sept jours à compter de la date de réception
            de l'Article par le vendeur.
          </span>
        </p>
        <p>
          <span lang="FR">8.4 Versement du montant de la transaction</span>
        </p>
        <p>
          <span lang="FR">
            Sewtoo versera sur le porte-monnaie Sewtoo du Vendeur le Prix de la Transaction, diminué
            de la Commission (0,70 euro par transaction + 5% du prix de l’article TTC) et des frais
            de port payés par l’acheteur lors&nbsp;:
          </span>
        </p>
        <p>
          <span lang="FR">
            -De la confirmation de la réception de l’Article par l’Acheteur via Sewtoo.
          </span>
        </p>
        <p>
          <span lang="FR">
            -En cas d’absence de confirmation de la réception par l’Acheteur, 10 jours après la
            réception de l’Article par l’Acheteur.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le Vendeur renonce dès à présent à toute réclamation concernant les sommes
            éventuellement produites par l'immobilisation des sommes perçues en son nom et pour son
            compte par Sewtoo à l'occasion d'une vente.
          </span>
        </p>
        <p>
          <b>
            <span lang="FR">Article 9. Garantie Satisfait ou Remboursé</span>
          </b>
        </p>

        <p>
          <span lang="FR">Sewtoo offre à ses Acheteurs une garantie satisfait ou remboursé.</span>
        </p>
        <p>
          <span lang="FR">
            Le retour d’un article permettant de bénéficier d’une garantie Satisfait ou Remboursé
            n’est admis que lors de ces différentes conditions&nbsp;:
          </span>
        </p>
        <p>
          <span lang="FR">9.1&nbsp;&nbsp;&nbsp;Article ne correspondant pas à l’annonce</span>
        </p>
        <p>
          <span lang="FR">
            L’Article ne correspond pas à l’Annonce mise en ligne par le Vendeur. L’Acheteur doit en
            informer le vendeur dans les 5 (cinq) jours suivant la réception de l’Article grâce au
            système de chat mis à sa disposition au niveau du détail de sa commande, passé ce délai,
            la garantie Satisfait ou Remboursé ne sera plus applicable.
          </span>
        </p>
        <p>
          <span lang="FR">
            Il sera alors donné au vendeur la possibilité de discuter le prix de l’article avec
            l’acheteur afin de trouver un terrain d’entente et valider la vente. Il est hautement
            recommandé aux acheteurs et aux vendeurs de trouver une solution amiable en cas de
            litige.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le vendeur disposera également de la possibilité de demander à l’acheteur le retour du
            produit. Dès lors que le vendeur choisira cette option, il devra s’acquitter auprès de
            Sewtoo du règlement des frais port lié au retour de l’article ainsi que des frais de
            port initialement payé par l’acheteur. À la réception du règlement du vendeur, Sewtoo
            enverra par email une étiquette retour prépayée à l’acheteur.
          </span>
        </p>
        <p>
          <span lang="FR">
            L’Acheteur devra retourner l’Article au vendeur dans un délai de 96 heures. Le retour de
            l’article s’effectue sous la responsabilité de l’Acheteur. Passé ce délai, la garantie
            Satisfait ou Remboursé ne sera plus applicable. Le vendeur sera remboursé des frais de
            retour ainsi que des frais de port initialement payé par l’acheteur et la vente sera
            considérée comme pleinement conclue.{' '}
          </span>
        </p>
        <p>
          <span lang="FR">
            Le remboursement de l'Acheteur se fera sous sept jours à compter de la date de réception
            de l'Article par le vendeur.
          </span>
        </p>
        <p>
          <span lang="FR">
            Dans l'hypothèse où l'Acheteur retournerait l'Article directement au Vendeur sans
            utiliser le système de retour mis à disposition par Sewtoo, les Frais de Port retour
            seront automatiquement remboursés au Vendeur, Sewtoo ne pouvant procéder à aucun
            contrôle de l'Article retourné.
          </span>
        </p>
        <p>
          <span lang="FR">9.2 Acheteur insatisfait pour des raisons qui lui sont propres</span>
        </p>
        <p>
          <span lang="FR">
            Dans le cas où un Acheteur aurait fait une erreur dans l’achat d’un Article&nbsp;: de
            taille, de couleur ou autre.&nbsp;
          </span>
        </p>
        <p>
          <span lang="FR">
            Il devra renvoyer l’Article au Vendeur à ses frais en faisant une demande d’étiquette
            retour à Sewtoo. Le retour de l'Article se fait sous la responsabilité de l'Acheteur.
          </span>
        </p>
        <p>
          <span lang="FR">
            Dès lors que le Produit sera réceptionné par le Vendeur, Sewtoo remboursera le Prix de
            l’Article à l’Acheteur sous un délai de 7 jours.&nbsp;
          </span>
        </p>
        <p>
          <span lang="FR">
            Dans le cas où le Vendeur ne se manifesterait pas, Sewtoo procèdera à la validation de
            la réception de l’Article et procèdera au remboursement du Prix de l’Article à
            l’Acheteur.
          </span>
        </p>
        <p>
          <span lang="FR">
            Le remboursement s’effectuera par recrédit de la carte bancaire de l’Acheteur ou de son
            porte-monnaie Sewtoo.
          </span>
        </p>
        <p>
          <b>
            <span lang="FR">Article 10 .Droit de rétractation</span>
          </b>
        </p>

        <p>
          <span lang="FR">
            10.1 L’Acheteur dispose d’un délai de 5 jours francs à compter de la livraison effective
            de l’Article pour se rétracter.
          </span>
        </p>
        <p>
          <span lang="FR">
            10.2 Les frais engagés pour le retour de l’Article sont à la charge exclusive de
            l’Acheteur. Le retour devra se faire obligatoirement via le système de retour fourni par
            Sewtoo .
          </span>
        </p>
        <p>
          <span lang="FR">
            10.3 Le remboursement se fera par recrédit sur la carte bancaire de l'Acheteur ou de son
            porte-monnaie Sewtoo.
          </span>
        </p>

        <p>
          <b>
            <span lang="FR">Article 11. Propriété intellectuelle</span>
          </b>
        </p>

        <p>
          <span lang="FR">
            11.1 La société Sewtoo détient tous les droits de propriété intellectuelle sur
            l'architecture générale ainsi que sur l'ensemble des éléments graphiques agrémentant
            l'architecture du Site Internet www.sewtoo.com{' '}
          </span>
        </p>
        <p>
          <span lang="FR">
            En conséquence, il est expressément&nbsp;&nbsp;interdit aux Utilisateurs de reproduire,
            par copie ou par imitation, tout ou partie dudit site Internet, par quelque procédé que
            ce soit et pour quelque raison que ce soit, sans autorisation de la société Sewtoo.
          </span>
        </p>
        <p>
          <span lang="FR">
            De même, il est interdit aux Utilisateurs de supprimer les mentions de droit d’auteur ou
            d’autres droits de Propriété intellectuelle du site Sewtoo. Les Utilisateurs sont
            seulement autorisés à faire une copie des pages Internet du site Sewtoo pour leur usage
            privé, personnel et non commercial, sous réserve que les copies de ces pages Web
            conservent les mentions de droit d’auteur ou d’autres droits de Propriété
            intellectuelle.&nbsp;
          </span>
        </p>
        <p>
          <span lang="FR">
            En outre, les Utilisateurs autorisent Sewtoo à reproduire et à représenter leur contenu,
            et en adapter le format le cas échéant, dans le cadre des fonctionnalités permettant de
            rendre accessible la place de marché Sewtoo via Internet et/ou d’autres supports de
            communications électroniques.
          </span>
        </p>

        <p>
          <b>
            <span lang="FR">Article 12. Litige</span>
          </b>
        </p>

        <p>
          <span lang="FR">
            Les présentes C.G.U. sont régies exclusivement par le droit français, et ce, même si les
            Utilisateurs sont de nationalité étrangère et/ou que le Contrat s'exécute en tout ou
            partie à l'étranger.
          </span>
        </p>
        <p>
          <span lang="FR">
            Tous les litiges auxquels les C.G.U. pourraient donner lieu notamment au sujet de leur
            validité, de leur interprétation, de leur exécution, qui n'aurait pas pu trouver dans un
            délai de TRENTE (30) jours, une solution amiable entre les parties, seront de la
            compétence exclusive du Tribunal français compétent.&nbsp;
          </span>
        </p>

        <p>
          <b>
            <span lang="FR">Article 13. Dispositions générales</span>
          </b>
        </p>

        <p>
          <span lang="FR">13.1 Modification des CGU</span>
        </p>
        <p>
          <span lang="FR">
            La société Sewtoo se&nbsp;&nbsp;réserve la possibilité de modifier les présentes C.G.U.
            à tout moment.
          </span>
        </p>
        <p>
          <span lang="FR">
            Les nouvelles C.G.U. s’appliqueront alors dès qu’elles auront été notifiées par Sewtoo
            aux Utilisateurs, peu importe le moyen de notification qui sera choisi.
          </span>
        </p>
        <p>
          <span lang="FR">13.2. Disjonction&nbsp;</span>
        </p>
        <p>
          <span lang="FR">
            Si certaines clauses des présentes C.G.U. étaient pour un motif quelconque, déclarées
            illégales ou non exécutables, lesdites illégalités ou impossibilités d’exécution
            n’affecteraient pas les autres clauses du contrat.
          </span>
        </p>
        <p>
          <span lang="FR">13.3 Langue du contrat&nbsp;&nbsp;</span>
        </p>
        <p>
          <span lang="FR">Le présent contrat est rédigé en langue française.</span>
        </p>
        <p>
          <span lang="FR">13.4 Zone géographique</span>
        </p>
        <p>
          <span lang="FR">
            La vente en ligne des produits présentés sur le site Sewtoo est réservée aux Acheteurs
            résidant uniquement en France métropolitaine et en Belgique.
          </span>
        </p>
      </div>
    </div>
  );
};

export default TermsOfServiceFrench;
