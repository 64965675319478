import React from 'react';
import css from './TermsOfService.module.css';
const TermsOfServiceEnglish = () => {
  return (
    <div>
      <div>
        <h1>Terms of Service</h1>
        <p>
          <span className={css.firstParagraph}>
            The company SEWTOO SAS, hereinafter Sewtoo, with a share capital of €5,000, registered
            in the Trade and Companies Register of Chartres under number 921 691 937, whose
            registered office is at Vernouillet (28500), 8 rue André-Marie Ampère , manages the
            sewtoo.com website accessible at the address www.sewtoo.com (hereinafter the “Sewtoo”).
          </span>
        </p>
        <p>
          <span>
            Sewtoo is a marketplace allowing buyers and sellers of sewing equipment to buy/sell
            their items using an online interface (www.sewtoo.com) ensuring and guaranteeing
            payment.
          </span>
        </p>
        <p>
          <span>
            Sewtoo acts as a trusted third party between the Buyer and the Seller. When a Buyer buys
            a product, his payment is retained by Sewtoo. This sum is then paid back to the Seller
            when the Buyer has confirmed that he has received the item and that he is satisfied with
            it.
          </span>
        </p>
        <p>
          <b>
            <span style={{ fontSize: '14.0pt' }}>Article 1 . Définitions of terms used</span>
          </b>
        </p>

        <p>
          <span>
            Sewtoo: Marketplace allowing the connection between Buyers and Sellers of sewing
            equipment.
          </span>
        </p>
        <p>
          <span>
            Buyer: means a User of the site www.sewtoo.com who wishes to buy or who has already
            bought one or more Item(s)
          </span>
        </p>
        <p>
          <span>
            User: refers to a buyer or seller interested in the services offered by Sewtoo
          </span>
        </p>
        <p>
          <span>Seller: means a particular User offering for sale one or more Item(s).</span>
        </p>
        <p>
          <span>
            Advertisement: means the offer for sale of one or more Item(s) issued by the Seller on
            Sewtoo.
          </span>
        </p>
        <p>
          <span>
            Article: concerns the sewing equipment offered for sale by a Seller on the Site, which
            corresponds to the criteria defined in Article 5 of these General Conditions and which
            constitutes the subject of the contract between the Seller and the Buyer.
          </span>
        </p>
        <p>
          <span>
            Catalog: represents all the items offered for sale on www.sewtoo.com by the same Seller
          </span>
          <span>.</span>
        </p>
        <p>
          <span>
            Commission: means the remuneration received by Sewtoo in return for the use of the Site
            by the Users. This commission is set at 5% of the sale price of the item plus 0.70 euro
            per order. Sewtoo's commission is only deducted when the sale has been made and the item
            received by the Buyer.
          </span>
        </p>
        <p>
          <span>
            Delivery method: shipping must be done by one of the carriers offered by Sewtoo
            (Colissimo, Mondial relay, bpost, etc.).
          </span>
        </p>
        <p>
          <span>
            Price of the Item: means the price offered by the Seller for the sale of his product.
            The Price of the Article does not include the Sewtoo Commission (5% of the price of the
            article + 0.70 euro) as well as the Shipping Costs.
          </span>
        </p>
        <p>
          <span>
            Site: means the website managed by the Sewtoo company accessible at the address
            https://www.sewtoo.com
          </span>
        </p>
        <p>
          <span>
            Trusted third party: Sewtoo acts as a trusted third party between the buyer and the
            seller. When a user buys a product, his payment is kept by Sewtoo in an escrow account.
            This sum is paid to the seller when the buyer has confirmed that he has received the
            item and is satisfied with it. Sewtoo thus guarantees the security of the payment and
            the transaction to the seller as well as the assurance of the delivery of the product
            ordered to the buyer.
          </span>
        </p>
        <p>
          <span>
            Escrow Account: means the escrow account managed by the payment service provider, on
            which the amount of the transaction is kept until the Transaction is finalized and
            payment is made.
          </span>
        </p>
        <p>
          <span>
            Electronic wallet ( E-wallet ): financial service provided by the payment service
            provider which allows Users to create electronic wallets on the Site. The e-wallet
            solution allows Users to receive funds for Items sold on the Site, store the funds, pay
            them to the User's bank account, or purchase Items from other Users on the Site.
          </span>
        </p>
        <p>
          <span>
            Transaction: Amount including the price of the Article defined by the Seller, the Sewtoo
            commission ((5% of the price of the article + 0.70 euro) as well as the Shipping Costs.
          </span>
        </p>

        <p>
          <b>
            <span style={{ fontSize: '14.0pt' }}>Article 2. Opening an account</span>
          </b>
        </p>

        <p>
          <span>
          Registration for Sewtoo is reserved for individual and adult Buyers and Sellers. Professional Users as well as minor users (under 18) are not accepted on the platform.
          </span>
        </p>
        <p>
          <span>2.1 Personal information</span>
        </p>
        <p>
          <span>
            To register on Sewtoo, the User will open an account using his email address. The User
            will provide complete and accurate information. Among the mandatory fields, the User
            must indicate his first name, last name, email address, password and country of
            residence.
          </span>
        </p>
        <p>
          <span>
            He undertakes to regularly update his personal data in order to keep them true, exact
            and complete. He will be held solely responsible for any consequences of the lack of
            updating of this information.
          </span>
        </p>
        <p>
          <span>
            In the event that the User provides false, inaccurate, outdated or incomplete
            information, the Sewtoo company reserves the right to refuse him, immediately and for
            the future, access to all or part of the Sewtoo marketplace and to suspend or terminate
            its contractual commitment.
          </span>
        </p>
        <p>
          <span>
            The password of each User is strictly personal, the User agrees to keep it confidential.
            In the event that the User becomes aware that another person is accessing his account,
            he will inform Sewtoo as soon as possible and confirm this information by mail.
          </span>
        </p>
        <p>
          <span>
            The User agrees to only register under a single profile. Thus, he undertakes not to
            create or use accounts other than the one initially created, whether under his own
            identity or that of a third party. Any derogation from this rule must be the subject of
            an explicit request from the User and an express, prior and specific authorization from
            Sewtoo. The fact of creating or using new accounts under its own identity or that of a
            third party without having requested and obtained authorization from Sewtoo may lead to
            the immediate suspension of the User's accounts and of all associated services.
          </span>
        </p>
        <p>
          <span>
            When selling an item, the Buyer accepts that his information allowing the good delivery
            of the product such as his name, first name, delivery address are sent to the Seller.
            Users undertake to keep this information confidential and not to disclose it. They
            undertake not to infringe on respect for the privacy of other Users and not to send them
            any message, object or documentation, in particular advertising, by any means whatsoever
            (in particular by post, telephone or electronic).
          </span>
        </p>
        <p>
          <span>2.2 Rules of good conduct</span>
        </p>
        <p>
          <span>
            The User undertakes to contact a Seller only in the event of a real interest in the
            product offered for sale. The User undertakes not to enter into direct contact with
            other Users outside of the question space provided for this purpose on Sewtoo. A sale
            made outside the Sewtoo site cannot hold the site responsible in the event of a dispute.
          </span>
        </p>
        <p>
          <span>
            The User undertakes not to use on Sewtoo, in particular by means of the description of
            the articles, remarks contrary to morality, offensive, defamatory or likely to damage
            the reputation of other Users. In the event of non-compliance with this obligation,
            Sewtoo reserves the right to suspend the User's account without prior notification. In
            the event of a repeat offence, Sewtoo reserves the right to permanently close the User's
            account.
          </span>
        </p>
        <p>
          <span>
            The User is prohibited from publishing photographs whose content is inappropriate
            (nudity, violence, etc.). Sewtoo reserves the right to delete any content deemed
            inappropriate and also to delete User accounts in certain cases.
          </span>
        </p>
        <p>
          <b>
            <span style={{ fontSize: '14.0pt' }}>Article 3 : protection of personal data</span>
          </b>
        </p>
        <p>
          <span>
            3.1. Sewtoo undertakes not to infringe the privacy of Sewtoo Users and the
            confidentiality of the information provided.
          </span>
        </p>
        <p>
          <span>
            The User can communicate his landline and mobile phone numbers as well as the
            information contained on his bank details and other optional information. The mandatory
            or optional nature of the data to be provided is indicated during their collection by a
            yellow underline bar.
          </span>
        </p>
        <p>
          <span>
            This personal information will be required when registering on the site, updating the
            Personal Information page, when buying or selling an Item, browsing Sewtoo and viewing
            Items of the catalog or to contact Customer Service.
          </span>
        </p>
        <p>
          <span>
            When registering on Sewtoo, the User may choose to receive offers, in particular
            commercial offers, sent by Sewtoo and/or its partners.
          </span>
        </p>
        <p>
          <span>
            Under no circumstances will the User's postal address be communicated to another User
            except in the event of the Purchase of an Item.
          </span>
        </p>
        <p>
          <span>
            3.2. In accordance with the law "Informatique et Libertés" n°78-17 of January 6, 1978,
            the User can at any time access the personal information concerning him that he has
            communicated to Sewtoo, and/or request its rectification, the addition , clarification,
            update or deletion by contacting Customer Service via the Contact Us section or by
            simple letter <span>(Sewtoo, 8 rue André-Marie Ampère, 28500 Vernouillet)</span>{' '}
            indicating their surname, first name, date and place of birth, postal address and email
            address.
          </span>
        </p>
        <p>
          <span>
            3.3. The User accepts that Sewtoo retains all documents, information and records
            concerning its activity on the Site.
          </span>
        </p>
        <p>
          <span>
            3.4. The User accepts that Sewtoo can retouch the photos of the articles that he has put
            online in order to optimize their visibility. The User also accepts that Sewtoo
            communicates on certain items that he has put up for sale. This always with the aim of
            facilitating and accelerating the sale of his/her products.
          </span>
        </p>

        <p>
          <b>
            <span style={{ fontSize: '14.0pt' }}>
              Article 4 - Role, rights and obligations of Sewtoo
            </span>
          </b>
        </p>

        <p>
          <span>4.1. Role of linking Sellers and Buyers</span>
        </p>
        <p>
          <span>
            Sewtoo undertakes to provide Users with organizational and functional tools to allow
            them to connect Sellers and Buyers and allow them, if necessary, to complete a sale of a
            Product.
          </span>
        </p>
        <p>
          <span>
            Sewtoo provides the Seller with a tool for publishing and distributing its sales offers
            on the Sewtoo marketplace. This tool allows the Seller to post advertisements for the
            sale of Products by describing their characteristics.
          </span>
        </p>
        <p>
          <span>
            Consequently, Sewtoo is in no way a reseller of the Products, never has the Products
            offered for sale, makes no guarantee as to their characteristics, their state of wear,
            their state of operation, and more generally cannot guarantee that Seller's Product will
            satisfy Buyer that it will not be returned or the sale will not be canceled by Buyer.
          </span>
        </p>
        <p>
          <span>4.2. Trusted third party</span>
        </p>
        <p>
          <span>
            Sewtoo is expressly authorized by the Seller to collect the Transaction Price in the
            name and on behalf of the Seller by means of the secure electronic payment system set up
            on the Sewtoo marketplace. The secure payment system is MangoPay, a public limited
            company under Luxembourg law, with a capital of 2,000,000 euros, whose registered office
            is located at 59 Boulevard Royal, L-2449 Luxembourg and registered with the Luxembourg
            Trade and Companies Register under number B173459 , authorized to carry out its activity
            in France under freedom of establishment, as an electronic money institution approved by
            the Commission de Surveillance du Secteur Financier, 110 route d'Arlon L-1150
            Luxembourg,
          </span>
        </p>
        <p>
          <span>
            4.3. <span>Availability of www.sewtoo.com</span>
          </span>
        </p>
        <p>
          <span>
            The Sewtoo company in no way guarantees the availability of the service in the event of
            technical incidents of the Internet network or malfunctions of the telephone
            connections.
          </span>
        </p>
        <p>
          <span>
            The Sewtoo company in no way guarantees the speed of access to the Sewtoo marketplace
            from other sites in the world.
          </span>
        </p>
        <p>
          <span>
            As part of the maintenance of its site, the company Sewtoo reserves the right to
            temporarily interrupt its services.
          </span>
        </p>
        <p>
          <span>
            In the event of inaccessibility of the service due to technical malfunctions within the
            competence of the Sewtoo company or its usual subcontractors, the Sewtoo company will
            make its best efforts to find a response to the problems revealed by the User.
          </span>
        </p>
        <p>
          <span>The responsibility of the Sewtoo company cannot be engaged:</span>
        </p>
        <p>
          <span>
            - if its server is unavailable for reasons of force majeure such as, in particular,
            long-term failures of the public electricity distribution network, strikes, riots, wars,
            storms, earthquakes, failures of the public telecommunications network, loss of
            connectivity Internet due to public and private operators on which the Sewtoo company
            depends;
          </span>
        </p>
        <p>
          <span>
            - for any technical reason which would result in the loss of Content stored or being
            downloaded on the Sewtoo company server.
          </span>
        </p>
        <p>
          <span>
            It is indeed up to the Users to keep a personal copy of the published Content.
          </span>
        </p>
        <p>
          <b>
            <span style={{ fontSize: '14.0pt' }}>
              Article 5. Role, rights and obligations of Seller
            </span>
          </b>
        </p>

        <p>
          <span>5.1. General provisions for the sale of a product</span>
        </p>
        <p>
          <span>
          No professional seller or minor (under 18) is authorized to create an account on sewtoo.com. Sewtoo reserves the right to immediately suspend the account of any seller considered to be a professional or a minor (under 18).
          </span>
        </p>
        <p>
          <span>
            The Seller undertakes that any Item put up for sale corresponds to immediately available
            material property of which he has full and complete ownership and capacity to sell. The
            Seller undertakes that the Items have no visible stain, hole, tear, indelible trace and
            that the dimensions indicated are correct. In the case of machines, the Seller
            undertakes to ensure that they are in working order (any technical problem encountered
            on the machine must be clearly mentioned in its description). In the event that such
            items are shipped to a buyer, Sewtoo reserves the right to require the seller to fully
            reimburse the items concerned to the buyer. In the event of a request for the return of
            the goods by the seller, the shipping costs related to their reshipment would be borne
            by the seller.
          </span>
        </p>
        <p>
          <span>The Seller also undertakes to keep its inventory up to date.</span>
        </p>
        <p>
          <span>
            The Seller undertakes to inform the Buyers of the essential characteristics of the
            Article. He is solely responsible for the description of the goods he offers for sale.
            It guarantees Sewtoo from any claim relating to the content and form of the said
            description if it were to be erroneous. The Seller undertakes to carry out all
            validations, verifications and to provide all necessary details so that the description
            and the elements of the offer are accurate and do not mislead the Buyer.
          </span>
        </p>
        <p>
          <span>
            The Seller undertakes not to insert links in the ad that have the purpose or effect of
            referring users of the Sewtoo marketplace to content located outside the Sewtoo site.
            The Seller is prohibited from inserting any link pointing to its e-mail address or, more
            generally, is prohibited from inserting any advertising (banner, commercial link, etc.)
            for the benefit of any site or any content which would not be hosted on the Sewtoo site.
          </span>
        </p>
        <p>
          <span>5.2. Sales bans</span>
        </p>
        <p>
          <span>
            The Seller undertakes that the Items comply perfectly with public order, good morals,
            the law in force in France, as well as the rights of third parties and that they are not
            likely to offend the sensitivity of minors.
          </span>
        </p>
        <p>
          <span>
            Sewtoo reserves the right to request, as proof of good faith, an invoice proving the
            ownership of the Article put online by the Seller or additional photographs of his
            Article.
          </span>
        </p>
        <p>
          <span>
            Sewtoo reserves the right to delete an advertisement if it does not comply with these
            General Terms and Conditions of Sale.
          </span>
        </p>
        <p>
          <span>5.3. Ad Changes</span>
        </p>
        <p>
          <span>
            Sewtoo reserves the right to ask a Seller to modify and/or delete his Advertisement
            and/or to do so directly, in particular if the Seller has not published his
            Advertisement in the appropriate category or if the Ad does not correspond to the
            product offered for sale.
          </span>
        </p>
        <p>
          <span>
            The Seller undertakes to only publish photos of the Items that he has taken himself.
            Sewtoo reserves the right to remove from its site any photo that has not been taken by
            the Seller.
          </span>
        </p>
        <p>
          <span>5.4. Determination of the selling price</span>
        </p>
        <p>
          <span>
            The Seller determines the Sale Price of the Item under his sole responsibility. Sewtoo
            draws the Seller's attention in particular to the fact that it is his responsibility not
            to set a Price that is too high, in the interest of concluding a sale.
          </span>
        </p>
        <p>
          <span>
            The Seller may indicate the New Sale Price. If the Seller does not know the exact sale
            price in store, he can indicate his best estimate. This Price corresponds to an
            indication of the price at which the seller declares to have purchased the Item that he
            is offering for second-hand sale. The User is informed that this new sale price has not
            been verified by Sewtoo.
          </span>
        </p>
        <p>
          <span>5.5 Request for additional information from a Buyer</span>
        </p>
        <p>
          <span>
            When a Buyer is interested in an offer to sell a product, the Seller will receive a
            request for additional information by email.
          </span>
        </p>
        <p>
          <span>
            The Seller undertakes never to offer a direct sale to the Buyer. If, however, such a
            sale proves to be made, Sewtoo reserves the right to suspend the account of the Seller
            and the Buyer. In addition, Sewtoo cannot then guarantee either the payment or the
            correct dispatch of the items ordered.
          </span>
        </p>
        <p>
          <b>
            <span style={{ fontSize: '14.0pt' }}>
              Article 6. Role, rights and obligations of Buyer
            </span>
          </b>
        </p>

        <p>
          <span>
          The Buyer undertakes to purchase the Items offered on Sewtoo on a personal basis. No professional Buyer or minor (under 18) is authorized to create an account on sewtoo.com. Sewtoo reserves the right to immediately suspend the account of any buyer considered to be a professional or a minor (under 18).
          </span>
        </p>
        <p>
          <span>6.1 Buyer Information</span>
        </p>
        <p>
          <span>
            The Buyer expressly acknowledges that his acceptance of an offer to sell will depend on
            the description by the Seller on the Sewtoo marketplace of the Product(s) offered for
            sale.
          </span>
        </p>
        <p>
          <span>
            The Buyer expressly acknowledges that the Sales contract is concluded under the
            resolutive condition that the Product ordered is available. The confirmation of the
            availability of the Product by the Seller entails the lifting of the resolutory
            condition. In case of infirmation of the availability of the Product, the Sales contract
            concluded between the Buyer and the Seller is automatically and immediately resolved
            (cancelled) and the obligations of each of the parties are automatically revoked. It is
            specified that in the event of a Sales contract relating to several Products, only the
            Sales contract relating to the unavailable Product will be resolved.
          </span>
        </p>
        <p>
          <span>6.2 Request for additional information</span>
        </p>
        <p>
          <span>
            Sewtoo provides the Buyer with computer tools allowing him to make a request to the
            Seller for additional information in addition to the description of the Item.
          </span>
        </p>
        <p>
          <span>
            The Buyer undertakes never to offer a direct sale to a Seller. If, however, such a sale
            proves to be made, Sewtoo reserves the right to suspend the account of the Seller and
            the Buyer. In addition, Sewtoo cannot then guarantee either the payment or the correct
            dispatch of the items ordered.
          </span>{' '}
          <span>
            The Buyer undertakes to purchase the Items offered on Sewtoo on a personal basis.{' '}
            <span>
              No Professional Buyer is authorized to create an account on sewtoo.com. Sewtoo
              reserves the right to immediately suspend the account of any buyer considered to be a
              professional.
            </span>
          </span>
        </p>
        <p>
          <b>
            <span
              style={{
                fontSize: '14.0pt',
              }}
            >
              Article 7. Conclusion of a sale
            </span>
          </b>
        </p>

        <p>
          <span>7.1. Conclusion of the sale / Placing of the order</span>
        </p>
        <p>
          <span>7.1.1. To confirm his order, the Buyer must pay the price of the Transaction.</span>
        </p>
        <p>
          <span>7.1.2 Sewtoo notifies the Seller of the Buyer's Purchase by email.</span>
        </p>
        <p>
          <span>
            7.1.3. The Purchase Offers made by the Buyer are independent of each other. If one or
            more of the Purchase Offers has or have not been accepted by the Seller(s) concerned,
            the Buyer's other Purchase Offers are not not lapse and bind the Buyer.
          </span>
        </p>
        <p>
          <span>7.2. Payment of the order</span>
        </p>
        <p>
          <span>7.2.1. Cash payment</span>
        </p>
        <p>
          <span>Secure payments via the integrated payment system. </span>
        </p>
        <p>
          <span>
            Sewtoo has entered into an agreement with MANGOPAY SA to process payments made using the
            Integrated Payment System and store information relating to credit cards, debit cards
            and any other means of payment offered on the Site. In order to use the Integrated
            Payment System, the User will conclude a contract directly with MANGOPAY S.A. and accept
            the general conditions of MANGOPAY SA. Users will be asked to confirm this when creating
            their e-wallet.
          </span>
        </p>
        <p>
          <span>
            Payment of the Total Price may be made by credit card, debit card or any other payment
            method offered on the Site. If a credit card, debit card or other means of payment
            suspected of belonging to a third party is provided by a User on the Site, the User may
            be required to provide additional proof that the credit card, the debit card or other
            means of payment is fine Users. If the Buyer has funds available in the electronic purse
            (Balance), these funds will be automatically used to make purchases on the Site from
            other Users. If the Buyer does not have sufficient funds in his e-wallet to cover the
            total amount due, he may cover the balance using other available means of payment.
          </span>
        </p>
        <p>
          <span>
            The Total Price is held in an Escrow account managed by MANGOPAY SA. When a Transaction
            is carried out, MANGOPAY SA receives the Buyer's funds on behalf of the Seller and keeps
            these funds in Escrow for disbursement purposes.
          </span>
        </p>
        <p>
          <span>
            Sewtoo supports its Users by offering them the services provided by MANGOPAY SA and
            provides them with direct assistance within the framework of these services. It is
            however specified, in order to avoid any ambiguity, that Sewtoo does not provide any
            payment processing service to Users. Users are responsible for providing accurate
            contact information for credit cards, debit cards, and any other payment methods offered
            on the site. Sewtoo declines all responsibility for the information provided by the User
            to the fullest extent permitted by applicable law.
          </span>
        </p>
        <p>
          <span>
            Under no circumstances does Sewtoo store or have access to the Buyers' bank details.
          </span>
        </p>
        <p>
          <span>7.2.2. Complete a transaction.</span>
        </p>
        <p>
          <span>
            The Total Price paid by the Buyer is kept in an Escrow account by MANGOPAY SA until the
            Transaction is finalised.
          </span>
        </p>
        <p>
          <span>The Transaction is finalized:</span>
        </p>
        <p>
          <span>(i) when Buyer clicks the All OK button during the Dispute Period; Where</span>
        </p>
        <p>
          <span>
            (ii) automatically upon expiration of the Dispute Period, if Buyer has not reported any
            issues through the I have a problem process during the Dispute Period; Where
          </span>
        </p>
        <p>
          <span>(iii) after resolution of a problem reported during the Dispute Period.</span>
        </p>
        <p>
          <span>
            As soon as the Transaction is finalized, the purchase price of the Item is transferred
            by MANGOPAY SA to the Seller's electronic purse. The User also has the possibility of
            asking MANGOPAY SA to transfer the funds stored in his electronic wallet to his personal
            bank account, without transfer fees. However, for the first payment, the User must
            provide the details of his bank account.
          </span>
        </p>
        <p>
          <span>7.2.3. Content of payment</span>
        </p>
        <p>
          <span>The amount paid by the Buyer includes:</span>
        </p>
        <p>
          <span>- the Price of the Item determined by the Seller,</span>
        </p>
        <p>
          <span>
            - The Sewtoo commission of 5% of the sale price of the item plus 0.70 euro per order.
          </span>
        </p>
        <p>
          <span>
            - The shipping costs set by Sewtoo according to the Colissimo and Mondial Relay prices{' '}
            <span>or any other carrier offered on the platform.</span>
          </span>
        </p>
        <p>
          <span>
            The amount of Shipping Costs paid by the Buyer will vary depending on the item
            purchased.
          </span>
        </p>
        <p>
          <span>
            The Buyer is debited with the total amount of the transaction (Item price + Sewtoo
            commission + Shipping costs) when he confirms his order by clicking on the "confirm my
            order and pay" button. If the Buyer does not receive the Item or is not satisfied with
            it, his bank account or his Sewtoo wallet (depending on the method of payment selected
            by the buyer when confirming his order) will be recredited.
          </span>
        </p>
        <p>
          <span>
            La Poste's rates for shipping goods are calculated based on the weight of the package.
          </span>
        </p>
        <p>
          <span>
            Mondial Relay's rates for shipping goods are calculated based on the weight of the
            package.
          </span>
        </p>
        <p>
          <span>7.3. Restrictions of use of the Integrated Payment System:</span>
        </p>
        <p>
          <span>7.3.1 Prohibition of Use by Third Parties. </span>
        </p>
        <p>
          <span>
            The right to use the Integrated Payment System is exclusively reserved for sales made on
            behalf of the User. The User may not resell, rent or, in any other way, allow third
            parties to use the Integrated Payment System for the purpose of remunerating services
            provided by third parties.
          </span>
        </p>
        <p>
          <span>7.3.2 Permitted Items Only.</span>
        </p>
        <p>
          <span>
            &nbsp;The Integrated Payment System is only reserved for Transactions relating to
            Articles appearing on the Site, to the exclusion of any other product or service.
          </span>
        </p>
        <p>
          <span>7.4. Currency. </span>
        </p>
        <p>
          <span>
            All prices displayed on the Site are expressed in euros (EUR) and include applicable
            VAT.
          </span>
        </p>
        <p>
          <span>7.5 ELECTRONIC WALLET (BALANCE)</span>
        </p>
        <p>
          <span>7.5.1 Electronic Wallet Settings.</span>
        </p>
        <p>
          <span>
            The Electronic Wallet (or Balance) is a payment service provided by MANGOPAY SA. When a
            User creates his electronic wallet, he concludes a contract directly with MANGOPAY SA
            and accepts the general conditions of MANGOPAY SA. Users are asked to confirm this when
            creating the e-wallet. For the first Sale Transaction, the Seller will be asked to open
            an electronic wallet by providing his name, first name, date of birth, address,
            nationality (if applicable) and telephone number (if applicable). If the Seller does not
            do so within five (5) days, the sale Transaction will be cancelled. Before the first
            sale transaction, sellers can also open the e-wallet on their own initiative via the
            account settings section (by selecting Balance and then clicking on Activate balance)
            and providing the required data.
          </span>
        </p>
        <p>
          <span>7.5.2 KYC checks.</span>
        </p>
        <p>
          <span>
            Once the confirmation of the electronic wallet has been made and the electronic wallet
            opened, the Users are subject to the regulatory procedures put in place by MANGOPAY SA,
            such as identity checks and other Know Your Customer requirements (or KYC), when they
            reach the thresholds set by MANGOPAY SA. MANGOPAY SA may also perform KYC checks on any
            User it suspects of engaging in potentially fraudulent or suspicious practices. If a
            user fails to comply with KYC requirements, their e-wallet may be suspended and the user
            will not be able to transfer funds until their identity is confirmed. If a User has
            uploaded a fraudulent document or taken any other action leading MANGOPAY SA to consider
            this User's behavior as fraudulent, the User will be deemed not to comply with KYC
            requirements and will not be able to disburse the funds. or dispose of it.
          </span>
        </p>
        <p>
          <span>
            7.5.3 <span>Transfer of funds held in an electronic wallet.</span>
          </span>
        </p>
        <p>
          <span>
            Users can transfer funds from their e-wallet to their bank account. This transfer will
            be carried out by MANGOPAY SA. To activate this option, the User must first access the
            settings of his User account and enter his bank account number, surname, first name and
            billing address. If the User reaches the KYC thresholds or if MANGOPAY SA suspects that
            the User is engaging in potentially suspicious or fraudulent activities, the User will
            not be able to transfer funds until he has met the applicable KYC requirements.
          </span>
        </p>
        <p>
          <span>7.6. Item Delivery</span>
        </p>
        <p>
          <span>
            When shipping the Item, the Seller undertakes to send the Item suitably packaged and in
            such a way that the Item cannot be impacted during transport. The Seller must keep proof
            of shipment of the package for the month following the date of shipment.
          </span>
        </p>
        <p>
          <span>7.6.1. Delivery to Buyer</span>
        </p>
        <p>
          <span>
            Once Sewtoo has collected payment of the Transaction Price from the Buyer, Sewtoo will
            communicate the Buyer's contact details to the Seller so that he can proceed with the
            delivery of the Item.
          </span>
        </p>
        <p>
          <span>
            The Seller is solely responsible for the proper performance of the obligation to deliver
            the Item ordered. The Seller undertakes to send the Item suitably packaged within 96
            hours following the date of availability of the shipping label pre-filled with the
            buyer's address (the seller will receive this label by email and it will be made
            available for download at the level of his customer account as soon as the order has
            been validated by the buyer). In the event of non-compliance by the Seller with the
            shipping conditions expressed herein, Sewtoo will cancel the transaction and reimburse
            the buyer accordingly. The seller agrees not to use the shipping label that will have
            been made available to him by Sewtoo after the shipping time of 96 hours. Any use of a
            shipping label after the shipping time of 96 hours will result in the invoicing of the
            seller of the following costs by Sewtoo: amount of the shipping costs initially
            calculated on the basis of the order concerned multiplied by two + costs file of 16.50
            euros. The buyer must pay all the fees invoiced by Sewtoo in order to continue to use
            his user account. Sewtoo reserves the right to deactivate the buyer's account until
            payment of the invoiced sums.
          </span>
        </p>
        <p>
          <span>
            In order to reduce the risks during delivery, and to allow the Buyer and the Seller to
            follow the shipment of the Item, the shipment must be made through the shipping system
            made available on Sewtoo .
          </span>
        </p>
        <p>
          <span>
            The Seller must keep proof of shipment of the package for the month following the date
            of shipment.
          </span>
        </p>
        <p>
          <span>7.6.2 Receipt of the Article</span>
        </p>
        <p>
          <span>
            Upon receipt of the Product(s) ordered, the Buyer undertakes to confirm through Sewtoo's
            customer service <span>within 5 (five) days t</span>he conformity of the Product(s).
            Failing this, the Buyer will file a complaint within the same period using customer
            service.
          </span>
        </p>
        <p>
          <span>
            Failing to have declared the good conformity of the products ordered or to have filed a
            complaint on the Sewtoo market place within <span>5 (five) days</span> from receipt of
            the order, the transaction is deemed to be perfect. The transaction is also deemed
            perfect upon confirmation by the Purchaser of the proper conformity of the Products
            ordered.
          </span>
        </p>
        <p>
          <span>
            The amount of the transaction minus the Sewtoo commission and the shipping costs will
            thus be paid to the Seller.
          </span>
        </p>
        <p>
          <b>
            <span style={{ fontSize: '14.0pt' }}>Article 8. Financial Conditions </span>
          </b>
        </p>

        <p>
          <span>8.1. Seller Payment</span>
        </p>
        <p>
          <span>
            The Seller authorizes Sewtoo to collect, in its name and on its behalf, the Transaction
            Price through the secure electronic payment system set up on the Site (here the MangoPay
            interface) or by any other means, which would be subsequently implemented by Sewtoo.
          </span>
        </p>
        <p>
          <span>8.2 Sewtoo remuneration</span>
        </p>
        <p>
          <span>
            The commission charged to the buyer by Sewtoo for each purchase is equal to 0.70 euro
            per transaction + 5% of the price of the item including tax. Shipping costs are not
            included in the Commission calculation basis.
          </span>
        </p>
        <p>
          <span>
            Sewtoo's commission is due only in the event of delivery of the Product(s) ordered and
            in the absence of cancellation of the sale, for any reason whatsoever.
          </span>
        </p>
        <p>
          <span>8.3 Payment of shipping costs</span>
        </p>
        <p>
          <span>
            The shipping costs charged to the buyer by Sewtoo for each purchase vary according to
            the addresses of the seller and the buyer, the weight and size of the package, the
            carrier selected by the buyer.
          </span>
        </p>
        <p>
          <span>Shipping costs may be refunded to the buyer if:</span>
        </p>
        <p>
          <span>- The item was not shipped by the seller within the time allowed.</span>
        </p>
        <p>
          <span>Or</span>
        </p>
        <p>
          <span>- The item was lost by the carrier during its delivery.</span>
        </p>
        <p>
          <span>
            The validation of the reimbursement as well as the delay for reimbursement of shipping
            costs are, in this case, dependent on the result of the investigation and the delay of
            the latter defined by the general conditions of use of the carrier selected by the
            buyer.
          </span>
        </p>
        <p>
          <span>Or</span>
        </p>
        <p>
          <span>
            - the package has been reported by the buyer as damaged to the carrier during its
            delivery or collection at a relay point.
          </span>
        </p>
        <p>
          <span>
            The validation of the reimbursement as well as the delay for reimbursement of shipping
            costs are, in this case, dependent on the result of the investigation and the delay of
            the latter defined by the general conditions of use of the carrier selected by the
            buyer.
          </span>
        </p>
        <p>
          <span>Or, if the following conditions are met:</span>
        </p>
        <p>
          <span>- The buyer has made a complaint addressed to the seller respecting the</span>
        </p>
        <p>
          <span>
            terms of the Satisfied or Reimbursed guarantee set out in Article 9 of these general
            conditions of use.
          </span>
        </p>
        <p>
          <span>
            - Following this complaint, the seller requested the return of the item at his expense.
          </span>
        </p>
        <p>
          <span>
            - The buyer has returned the item in accordance with the terms of the Satisfied or
            Refunded guarantee set out in article 9 of these general conditions of use.
          </span>
        </p>
        <p>
          <span>
            The reimbursement of the Buyer will be made within seven days from the date of receipt
            of the Article by the seller.
          </span>
        </p>
        <p>
          <span>8.4 Payment of the transaction amount</span>
        </p>
        <p>
          <span>
            Sewtoo will pay the Seller's Sewtoo wallet the Transaction Price, less the Commission
            (0.70 euro per transaction + 5% of the price of the item including tax) and the shipping
            costs paid by the buyer when:
          </span>
        </p>
        <p>
          <span>- Confirmation of receipt of the Article by the Buyer via Sewtoo.</span>
        </p>
        <p>
          <span>
            - In case of absence of confirmation of receipt by the Buyer, 5 days after receipt of
            the Article by the Buyer.
          </span>
        </p>
        <p>
          <span>
            The Seller hereby waives any claim concerning the sums possibly produced by the
            immobilization of the sums collected in its name and on its behalf by Sewtoo on the
            occasion of a sale.
          </span>
        </p>
        <p>
          <b>
            <span style={{ fontSize: '14.0pt' }}>Article 9. Satisfied or Refunded guarantee</span>
          </b>
        </p>

        <p>
          <span>Sewtoo offers its Buyers a money-back guarantee.</span>
        </p>
        <p>
          <span>
            The return of an item allowing you to benefit from a Satisfied or Refunded guarantee is
            only accepted under these different conditions:
          </span>
        </p>
        <p>
          <span>9.1 Article not corresponding to the offer</span>
        </p>
        <p>
          <span>
            The Item does not correspond to the offer posted by the Seller. The Buyer must inform
            the seller within 5 (five) days of receipt of the Article using the chat system made
            available to him at the level of the detail of his order, after this period, the
            Satisfied or Refunded guarantee will not be more applicable.
          </span>
        </p>
        <p>
          <span>
            The seller will then be given the opportunity to discuss the price of the item with the
            buyer in order to find common ground and validate the sale. It is highly recommended
            that buyers and sellers find an amicable solution in the event of a dispute.
          </span>
        </p>
        <p>
          <span>
            The seller will also have the possibility of asking the buyer to return the product.
            Once the seller chooses this option, he will have to pay Sewtoo the price of the
            shipping costs related to the return of the item as well as the shipping costs initially
            paid by the buyer. Upon receipt of payment from the seller, Sewtoo will email a prepaid
            return label to the buyer.
          </span>
        </p>
        <p>
          <span>
            The Buyer must return the Item to the Seller within 96 hours. The return of the article
            is carried out under the responsibility of the Buyer. After this period, the Satisfied
            or Refunded guarantee will no longer apply. The seller will be reimbursed for the return
            costs as well as the shipping costs initially paid by the buyer and the sale will be
            considered fully concluded.
          </span>
        </p>
        <p>
          <span>
            The reimbursement of the Buyer will be made within seven days from the date of receipt
            of the Article by the seller.
          </span>
        </p>
        <p>
          <span>
            In the event that the Buyer returns the Item directly to the Seller without using the
            return system made available by Sewtoo, the Return Shipping Costs will be automatically
            reimbursed to the Seller, Sewtoo being unable to carry out any checks on the returned
            Item.
          </span>
        </p>
        <p>
          <span>9.2 Dissatisfied buyer for his own reasons</span>
        </p>
        <p>
          <span>
            In the event that a Buyer has made an error in the purchase of an Item: size, color or
            other.
          </span>
        </p>
        <p>
          <span>
            He must return the Item to the Seller at his own expense by requesting a return label
            from Sewtoo. The return of the Article is made under the responsibility of the Buyer.
          </span>
        </p>
        <p>
          <span>
            As soon as the Product is received by the Seller, Sewtoo will reimburse the Price of the
            Article to the Buyer within 7 days.
          </span>
        </p>
        <p>
          <span>
            In the event that the Seller does not appear, Sewtoo will validate receipt of the Item
            and will refund the Price of the Item to the Buyer.
          </span>
        </p>
        <p>
          <span>
            Reimbursement will be made by crediting the Buyer's bank card or Sewtoo wallet.
          </span>
        </p>
        <p>
          <b>
            <span style={{ fontSize: '14.0pt' }}>Article 10 .Right of withdrawal </span>
          </b>
        </p>

        <p>
          <span>
            10.1 The Buyer has a period of 5 clear days from the actual delivery of the Article to
            withdraw.
          </span>
        </p>
        <p>
          <span>
            10.2 The costs incurred for the return of the Item are the sole responsibility of the
            Buyer. The return must be made via the return system provided by Sewtoo.
          </span>
        </p>
        <p>
          <span>
            10.3 Reimbursement will be made by crediting the Buyer's bank card or Sewtoo wallet.
          </span>
        </p>

        <p>
          <b>
            <span style={{ fontSize: '14.0pt' }}>Article 11. Intellectual property</span>
          </b>
        </p>

        <p>
          <span>
            11.1 The Sewtoo company holds all the intellectual property rights to the general
            architecture as well as to all the graphic elements embellishing the architecture of the
            Website <span>www.sewtoo.com</span>
          </span>
        </p>
        <p>
          <span>
            Consequently, Users are expressly prohibited from reproducing, by copy or imitation, all
            or part of said website, by any process whatsoever and for any reason whatsoever,
            without authorization from Sewtoo.
          </span>
        </p>
        <p>
          <span>
            Similarly, Users are prohibited from deleting copyright notices or other Intellectual
            Property rights from the Sewtoo site. Users are only authorized to make a copy of the
            Internet pages of the Sewtoo site for their private, personal and non-commercial use,
            provided that the copies of these Web pages retain the notices of copyright or other
            Intellectual Property rights. .
          </span>
        </p>
        <p>
          <span>
            In addition, Users authorize Sewtoo to reproduce and represent their content, and adapt
            its format if necessary, as part of the functionalities making the Sewtoo marketplace
            accessible via the Internet and/or other electronic communication media.{' '}
          </span>
        </p>
        <p>
          <b>
            <span style={{ fontSize: '14.0pt' }}>Article 12. Dispute</span>
          </b>
        </p>

        <p>
          <span>
            These C.G.U. are governed exclusively by French law, even if the Users are of foreign
            nationality and/or the Contract is executed in whole or in part abroad.
          </span>
        </p>
        <p>
          <span>
            All disputes to which the C.G.U. could give rise in particular to the subject of their
            validity, their interpretation, their execution, which could not have found within a
            period of THIRTY (30) days, an amicable solution between the parties, will be the
            exclusive jurisdiction of the French Court competent.
          </span>
        </p>

        <p>
          <b>
            <span style={{ fontSize: '14.0pt' }}>Article 13. General provisions</span>
          </b>
        </p>

        <p>
          <span>13.1 Modification of the T&amp;Cs</span>
        </p>

        <p>
          <span>The Sewtoo company reserves the right to modify these T.C.U. at any time.</span>
        </p>
        <p>
          <span>
            The new C.G.U. will then apply as soon as they have been notified by Sewtoo to the
            Users, regardless of the means of notification that will be chosen.
          </span>
        </p>

        <p>
          <span>13.2. Disjunction</span>
        </p>
        <p>
          <span>
            If certain clauses of these C.G.U. were for any reason declared illegal or
            non-executable, said illegalities or impossibilities of execution would not affect the
            other clauses of the contract.
          </span>
        </p>

        <p>
          <span>13.3 Contract language</span>
        </p>
        <p>
          <span>This contract is written in French.</span>
        </p>

        <p>
          <span>13.4 Geographic area</span>
        </p>
        <p>
          <span>
            The online sale of products presented on the Sewtoo site is reserved for Buyers residing
            only in mainland France and Belgium.
          </span>
        </p>
      </div>
    </div>
  );
};

export default TermsOfServiceEnglish;
